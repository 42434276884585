
import './App.css';
import logoadtech from './pics/ADTECH_logo.svg'
import { BrowserRouter, Link } from 'react-router-dom';

//import Home from './home';

function App() {
  return (
    <div className="App">
      <div className="main">
    
          <div className="headplace">
            <img src={logoadtech} className="LOGO"  alt="logo"/>
            {/*<p className="TRANSLATE">EN | DE</p>*/}
            <div className="HEADLINE">
              <p className="HEADLINE_Kontakt">Kontaktformular</p>
            </div>
            <div className="NAVBAR">
            
            </div>
          </div>

        
        <div className="CONTENT_HOME" > 
        <p className="Homeheadline">Support Request</p> 
        <p className="intro">Bitte erstellen Sie Ihre Anfrage in folgenden Punkten aufgeführt:</p><br/>
        <ul className="homelist">
                <li>Zusammenfassung: Bitte fassen Sie ihren Support case in knappen Stichpunkten zusammmen.</li>
                <li>Beschreibung: Beschreiben Sie alles was wichtig ist, um zeitnah mit der Bearbeitung zu beginnen.</li>
                <li>Name: Tragen Sie bitte Ihren Namen ein </li>
                <li>Email: Tragen Sie ihre Mailadresse ein</li>
              
        </ul>


<button id="myCustomTrigger">
Kontaktformular
</button>

</div>
      </div>
    </div> //LASTDIVOFAPP
  );
}

export default App;
